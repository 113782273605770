import React from 'react'
import ArticlesList from '../components/ArticlesList'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import WeDoCtoBanner from '../components/sections/WeDoCtoBanner'

const Articles = ({ data: { contentfulPage: pageData } }) => {
  return (
    <Layout page={pageData} showShadow={false} showProgress>
      <ArticlesList />
      {/*<WeDoCtoBanner />*/}
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPage(pageLink:{eq: "/articles"}){
        ...pageFields
    }
  }
`

export default Articles
