import React, { useState } from 'react'
import ArticleCard from './elements/ArticleCard'
import { useArticlesList } from '../custom-query-hooks/useArticlesList'


const ArticlesList = () => {
  const articlesList = useArticlesList()
  const [articles, setArticles] = useState(articlesList)
  const [filter, setFilter] = useState('all')

  const filterArticles = tagName => {
    //window[`scrollTo`]({ top: 380, behavior: `smooth` })
    const filters = document.getElementById('main')
    filters.scrollIntoView({ block: 'start', behavior: 'smooth' })

    setFilter(tagName)
    if (tagName === 'all') {
      setArticles(articlesList)
      return
    }

    const newArticles = []
    articlesList.forEach(article => {
      article.tags &&
        article.tags.map(
          mappedTag =>
            mappedTag.name.toLowerCase() === tagName.toLowerCase() &&
            newArticles.push(article)
        )
    })
    setArticles(newArticles)
  }

  const allTags = {}

  articlesList.forEach(article => {
    article.tags.forEach(tag => {
      if (allTags[tag.name]) {
        allTags[tag.name] = allTags[tag.name] + 1
      } else {
        allTags[tag.name] = 1
      }
    })
  })

  const newTags = Object.entries(allTags).sort((a, b) => {
    const [firstTag] = a
    const [secondTag] = b
    return firstTag.localeCompare(secondTag)
  })

  return (
    <section>
      <div className='sticky z-10 p-2 lg:p-4 bg-white top-navbar md:top-navbarDesktop shadow-md'>
        <div className='container flex flex-wrap justify-center items-center gap-1 lg:gap-2 lg:px-12'>
          {filter === 'all' ? (
            <span className='p-2 bg-transparent text-xs lg:text-sm font-spar text-black shadow-sm rounded-md px-2 lg:px-4 lowercase'>
              Show All
            </span>
          ) : (
            <button
              className='p-2 bg-primary text-xs lg:text-sm font-spar text-secondary-dark shadow-sm rounded-md px-2 lg:px-4 hover:text-black hover:bg-transparent lowercase'
              onClick={() => filterArticles('all')}
            >
              Show All
            </button>
          )}
          {newTags.map((tagArray, index) => {
            const [tagName, value] = tagArray
            if (filter === tagName.toLowerCase()) {
              return (
                <span className='p-1.5 lg:p-2 bg-transparent text-xs lg:text-sm font-spar text-black shadow-sm rounded-md px-2 lg:px-4 lowercase'>
                  {tagName}{' '}
                  <span className='inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-primary bg-gray-50 rounded-full'>
                    {value}
                  </span>
                </span>
              )
            } else {
              return (
                <button
                  key={index}
                  className='p-1.5 lg:p-2 bg-primary text-xs lg:text-sm font-spar text-secondary-dark shadow-sm rounded-md px-2 lg:px-4 hover:text-black hover:bg-transparent lowercase'
                  onClick={() => filterArticles(tagName.toLowerCase())}
                >
                  {tagName}{' '}
                  <span className='inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-secondary-dark bg-gray-50 rounded-full'>
                    {value}
                  </span>
                </button>
              )
            }
          })}
        </div>
      </div>
      <div className='container px-4'>
        {articles.map((article, index) => (
          <ArticleCard key={index} article={article} />
        ))}
      </div>
    </section>
  )
}

export default ArticlesList
