import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import slugify from 'slugify'

const ArticleCard = ({ article }) => {
  const { title, description, tags, publishDate, heroImage, readTime } = article
  const image = getImage(heroImage)
  const slug = slugify(title, { lower: true })

  return (
    <article>
      <Link
        className='group flex flex-col md:flex-row md:mt-8 bg-white md:bg-yellow-50 md:rounded-2xl shadow-lg xl:hover:shadow-2xl'
        to={`/articles/${slug}`}
        title={title}
      >
        <div className='flex w-full md:w-2/5 mt-4 md:mt-0 md:rounded-l-2xl safari-image-fix'>
          <GatsbyImage
            alt={title}
            image={image}
            className='flex-1 w-full h-40 md:h-72 xl:h-96 mb-4 md:mb-0 md:rounded-l-2xl transition-all duration-300 ease-in-out xl:opacity-75 group-hover:opacity-100'
            as='section'
          ></GatsbyImage>
        </div>
        <div className='flex flex-col justify-between p-4 xl:p-8 w-full md:w-3/5 self-center align-middle md:h-72 xl:h-96'>
          <div>
            <h2 className='text-xl md:text-2xl xl:text-4xl -m-4 md:m-0'>
              {title}
            </h2>
            {tags && (
              <div className='w-max bg-primary shadow-sm rounded-sm px-2 mx-auto md:mx-0 mt-6 z-50 md:mt-2'>
                {tags.map((tags, index) => (
                  <span className='text-sm text-secondary-dark lowercase font-semibold tracking-widest'>
                    {index > 0 && ' - '}
                    {tags.name}
                  </span>
                ))}
              </div>
            )}
            <p className='m-0 mt-8 md:mt-4 text-sm md:text-base lg:text-xl'>
              {description.description}
            </p>
          </div>
          <div className='flex justify-between mt-8 md:mt-0'>
            <span className='text-sm pb-0 text-right lowercase'>
              read time: {readTime} mins
            </span>
            <span className='text-sm pb-0 lowercase'>
              published {publishDate}
            </span>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default ArticleCard
