import { graphql, useStaticQuery } from 'gatsby'

export const useArticlesList = () => {
  const { allContentfulArticle } = useStaticQuery(
    graphql`
      query articleListQuery {
        allContentfulArticle(sort: { fields: publishDate, order: DESC }) {
          nodes {
            title
            description {
              description
            }
            tags {
              name
            }
            publishDate(formatString: "DD MMMM, YYYY")
            heroImage {
              gatsbyImageData
            }
            readTime
          }
        }
      }
    `
  )
  return allContentfulArticle.nodes
}